<template>
  <div>
    <vs-card>
      <div
        style="display: flex; justify-content: space-between"
        @click="createUserHandler"
      >
        <h4 class="mb-2 ml-5 mt-2">Account Details</h4>
        <vs-button icon-pack="feather" class="mr-2">Create</vs-button>
      </div>
      <form autocomplete="off" @submit.prevent>
        <create-user-basic
          ref="basicStep"
          @upload-image="uploadImage"
          @data-updated="dataUpdated"
          :create-user-data="createUserData"
        />
        <create-user-nurse
          ref="nurseStep"
          v-if="createUserData.apType === 'Nurse'"
          @upload-image="uploadImage"
          @data-updated="dataUpdated"
          :create-user-data="createUserData"
        />
        <create-user-doctor
          ref="doctorStep"
          v-if="
            ['Nurse Practitioner', 'Physician Assistant', 'Doctor'].includes(
              createUserData.apType
            )
          "
          @upload-image="uploadImage"
          @data-updated="dataUpdated"
          :create-user-data="createUserData"
        />
      </form>
    </vs-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/main";
import axios from "@/axios";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import CreateUserBasic from "./CreateUserBasic";
import CreateUserNurse from "./CreateUserNurse";
import CreateUserDoctor from "./CreateUserDoctor";
import moment from "moment";

export default {
  name: "create-doctor",
  props: {
    fetchClinicsBy: {
      type: String,
      default: "franchise",
    },
  },
  components: {
    "v-select": vSelect,
    Datepicker,
    CreateUserBasic,
    CreateUserNurse,
    CreateUserDoctor,
  },
  computed: {
    ...mapState("nurse", ["createUserType"]),
  },
  data: () => ({
    createUserData: {
      profileImage: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      apType: "",
      userType:"",
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      ahpraCertificate: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
      insuranceCertificate: "",
      emailVerifiedAt: new Date().toISOString(),
      authorizingDoctor: "",
      dateOfBirth: "",
      contactNumber: "",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: { type: "Point", coordinates: [] },
      },
      doctorType: "contract",
      abn: "",
      prescriberNumber: "",
      drCredentials: "",
    },
    activeClinicId: null,
  }),
  mounted() {
    if (this.createUserType) {
      this.createUserData.apType = this.createUserType;
      this.$store.commit("nurse/CREATE_USER_TYPE", undefined);
    }
  },
  methods: {
    ...mapActions("franchise", ["registerFranchiseNurse", "assignClinics"]),
    ...mapActions("doctor", ["createDoctor"]),
    ...mapActions("basicUser", ["createBasicUser"]),

    uploadImage(event, key = "") {
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.createUserData.profileImage = res.data.Location;
          }
          if (key === "ahpraUpload") {
            self.createUserData.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            self.createUserData.insuranceCertificate = res.data.Location;
          }
          EventBus.$emit("upload-result", key);
        })
        .catch((err) => {
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
          EventBus.$emit("upload-result", false);
        });
    },
    dataUpdated(key, value) {
      this.createUserData[key] = value;
    },
    async createUserHandler() {
      let isValid = await this.$refs.basicStep.$validator.validate();

      if (this.createUserData.apType === "Nurse") {
        let isNurseStepValid = await this.$refs.nurseStep.$validator.validate();
        isValid = isValid && isNurseStepValid;
      }

      if (
        ["Nurse Practitioner", "Physician Assistant", "Doctor"].includes(
          this.createUserData.apType
        )
      )
      {
        const isDoctorStepValid =
          await this.$refs.doctorStep.$validator.validate();
        isValid = isValid && isDoctorStepValid;
      }

      if (isValid) {
        if (this.createUserData.apType === "Nurse") {
          this.createNurseHandler();
        }
        if (this.createUserData.apType === "Basic") {
          this.createBasicUserHandler();
        }

        if (
          ["Nurse Practitioner", "Physician Assistant", "Doctor"].includes(
            this.createUserData.apType
          )
        )
        {
            if (this.createUserData.userType === 'nurse') {
                this.createNurseHandler();
            }else {
                this.createDoctorHandler();
            }
        }
      }
    },

    async createBasicUserHandler() {
      let self = this;

      self.$vs.loading();
      let payload = {
        email: this.createUserData.email,
        emailVerifiedAt: new Date().toISOString(),
        firstName: this.createUserData.firstName,
        hasApprovedByAdmin: true,
        lastName: this.createUserData.lastName,
        password: this.createUserData.password,
      };

      this.createBasicUser(payload)
        .then((res) => {
          if(this.activeClinicId){
            this.assignClinics({
              nurseId: res.data.data._id,
              clinicIds: [this.activeClinicId],
            })
          }
          
          self.$vs.notify({
            title: "Basic user created",
            text: "Basic user created successfully.",
            color: "success",
          });
          self.$vs.loading.close();
          this.$router.push(`/super-admin/basic-users/${res.data.data._id}`);
        })
        .catch((err) => {
          if (err.status === 422) {
            self.$vs.notify({
              title: "Failed",
              text: "Failed to create basic user. Try again!",
              color: "danger",
            });
            self.mapServerErrors(self, err);
          }
          if (err.status === 409) {
            self.errors.add({
              field: "email",
              msg: err.data.message,
            });
            self.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
          }

          if (err.status === 417) {
            self.errors.add({
              field: "abn",
              msg: err.data.message,
            });
            self.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
          }

          if (err.status === 420) {
            self.errors.add({
              field: "dateOfBirth",
              msg: err.data.message,
            });
            self.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
          }
          if (err.status === 500) {
            self.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
          }
          self.$vs.loading.close();
        });
    },

    async createDoctorHandler() {
      let self = this;

      // comment out for now
      // if (!this.$isAuRegion()) {
      //   delete this.createUserData.address;
      // }

      self.$vs.loading();
      let payload = {};
      this.createUserData.emailVerifiedAt = new Date().toISOString();
      payload.userDetails = this.createUserData;
      payload.userDetails.hasApprovedByAdmin = true;

      await this.createDoctor(payload)
        .then((res) => {
          if(this.activeClinicId){
            this.assignClinics({
              nurseId: res.data.data._id,
              clinicIds: [this.activeClinicId],
            })
          }

          self.$vs.notify({
            title: "User Created",
            text: "User created successfully.",
            color: "success",
          });
          self.$vs.loading.close();
          this.$router.push(`/super-admin/doctors/${res.data.data._id}/edit`);
        })
        .catch((err) => {
          if (err.status === 422) {
            self.$vs.notify({
              title: "Failed",
              text: "Failed to create user. Try again!",
              color: "danger",
            });
            self.mapServerErrors(self, err);
          }
          if (err.status === 409) {
            self.errors.add({
              field: "email",
              msg: err.data.message,
            });
            self.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
          }

          if (err.status === 417) {
            self.errors.add({
              field: "abn",
              msg: err.data.message,
            });
            self.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
          }

          if (err.status === 420) {
            self.errors.add({
              field: "dateOfBirth",
              msg: err.data.message,
            });
            self.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
          }
          if (err.status === 500) {
            self.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
          }
          self.$vs.loading.close();
        });
    },

    async createNurseHandler() {
      const self = this;
      self.$vs.loading();

      let payload = {};
      self.createUserData.emailVerifiedAt = new Date().toISOString();
      payload.userDetails = self.createUserData;
      payload.userDetails.hasApprovedByAdmin = true;

      await self
        .registerFranchiseNurse(payload)
        .then((res) => {
          if(this.activeClinicId){
            this.assignClinics({
              nurseId: res.data.data._id,
              clinicIds: [this.activeClinicId],
            })
          }
          self.$vs.notify({
            title: "Nurse Created",
            text: "Nurse created successfully.",
            color: "success",
          });
          self.$vs.loading.close();
          this.$router.push(`/super-admin/nurses/${res.data.data._id}`);
        })
        .catch((err) => {
          if (err.status === 422) {
            self.mapServerErrors(self, err);
            self.$vs.notify({
              title: "Failed",
              text: "Failed to create nurse. Try again!",
              color: "danger",
            });
            self.$vs.loading.close();
          }
          if (err.status === 409) {
            self.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
            self.errors.add({
              field: "email",
              msg: err.data.message,
            });
            self.$vs.loading.close();
          }
          if (err.status === 500) {
            self.errors.add({
              field: "email",
              msg: err.data.message,
            });
            self.$vs.loading.close();
          }
        });
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
  },
  async created() {
    this.admin = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}User`)
      );
    this.activeClinicId = (await this.$route.params.activeClinicId) ? this.$route.params.activeClinicId : null
  }
};
</script>
<style lang="scss">
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
.is-danger {
  border: 1px solid #ea5455 !important;
}
.is-true {
  border: 1px solid #28c76f !important;
}
.bg-color-done {
  background: #d4f4e2;
  color: #28c76f !important;
  border: 1px solid #28c76f !important;
  border-left: hidden !important;
}

.bg-color-error {
  background: #fbdddd;
  color: #ea5455 !important;
  border: 1px solid #ea5455 !important;
  border-left: hidden !important;
}

.image-upload {
  cursor: pointer;
}
.avatar {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.style-chooser .vs__search::placeholder {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  letter-spacing: 0.01rem;
}
</style>
